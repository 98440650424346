import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import abadiLimitsValidator from '@/composables/kmls/abadiLimitsValidator';
import handleClicks from '@/composables/mapEvents/handleClicks';

const filesManager = () => {
    const { showGlobalToast } = globalToast();
    const { validateAbadiLimitNGetInfo } = abadiLimitsValidator();
    const { loadAbadiFilesListFromServer } = handleClicks();

    const checkNApproveFile = (id: any, uploader: any) => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        const featureGeometry = ai_currentabadi.abadiFeat.getGeometry();

        const { validAbadiLimit, district, taluk } = <any>validateAbadiLimitNGetInfo(featureGeometry);
        // console.log(validAbadiLimit, district, taluk);

        if(validAbadiLimit){
            approveFile(id, uploader);
        } else {
            showGlobalToast('Trying to Approve File in Unauthorized Jurisdiction...');
        }
    }

    const approveFile = (id: any, uploader: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'files',
            request: 'approvefile',
            validateusername: username,
            validatepassword: password,
            id, uploader
        }

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                showGlobalToast('File Approved');

                const abadilimituuid = store.getters.ai_currentabadi.abadilimituuid;
                loadAbadiFilesListFromServer({ abadilimituuid });
            } else {
                showGlobalToast('Unauthorized Action... No Changes Made...');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });

    }

    const checkNDownloadFile = (fileFullName: any) => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        const featureGeometry = ai_currentabadi.abadiFeat.getGeometry();

        const { validAbadiLimit, district, taluk } = <any>validateAbadiLimitNGetInfo(featureGeometry);
        // console.log(validAbadiLimit, district, taluk);

        if(validAbadiLimit){
            download(fileFullName);
        } else {
            showGlobalToast('Trying to Download File from Unauthorized Jurisdiction...');
        }
    }

    const download = (fileFullName: any) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;
        const fileServerURL = store.getters.g_httpfilePostURL;

        const href = `${fileServerURL}/files/${fileFullName}?validateusername=${username}&validatepassword=${password}`;
        console.log(href);

        let element = document.createElement('a');
        element.setAttribute('href', href);
        element.setAttribute('target', '_blank');
      
        element.style.display = 'none';
        document.body.appendChild(element);
      
        element.click();
      
        document.body.removeChild(element);
      }
    

    return { checkNApproveFile, checkNDownloadFile };
}

export default filesManager;