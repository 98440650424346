
import { computed, defineComponent, onMounted, ref } from 'vue';
import './AbadiManager.scss';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import renderDateFromServer from '@/composables/common/renderDateFromServer';
import filesManager from '@/composables/abadifiles/filesManager';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { renderDate } = renderDateFromServer();
        const { checkNApproveFile, checkNDownloadFile } = filesManager();

        const ai_currentabadi = computed(() => store.getters.ai_currentabadi);
        const ai_abadilistfetchstatus = computed(() => store.getters.ai_abadilistfetchstatus);
        const abadiObjAvailInStore = computed(() => 'abadilimituuid' in store.getters.ai_currentabadi);
        const af_filesList = computed(() => store.getters.af_filesList);

        const isApprover = computed(() => {
            let role = store.getters.g_userdetails.role;
            if(role == 'STATE_APPROVER' || role == 'KGDC_APPROVER') {
                return true;
            } else {
                return false;
            }
        });

        const inputTypes = <any>{
            'text': ['abadilimitname', 'grampanchayat', 'villagelgdcode', 'villagename'],
            'number': ['abadiareascountinvillage', 'noofproperties'],
            'date': ['markingenddate', 'markingstartdate']
        };

        const returnInputType = (col: any) => {
            let inputType = Object.keys(inputTypes)
            .filter((key: any) => {
                let foundCol = false;
                let colArry = inputTypes[key];
                for (let i = 0; i < colArry.length; i++){
                    if(colArry[i] == col) foundCol = true;
                }
                return foundCol;
            });
            return inputType;
        }

        const ai_currentabadiInfo_mod = computed(() => {
            let infoSets = <any>[];
            let ai_currentabadiInfo = store.getters.ai_currentabadiInfo;
            // console.log(ai_currentabadiInfo);

            let ai_currentabadiInfo_arry = Object.keys(ai_currentabadiInfo)
            .filter((key) => ['certbase64', 'certextension', 'deleted'].indexOf(key) == -1)
            .map((key) => [key, ai_currentabadiInfo[key]]);
            
            for (let i = 0; i < ai_currentabadiInfo_arry.length; i = i + 3){
                let el1 = ai_currentabadiInfo_arry[i] != undefined ? ai_currentabadiInfo_arry[i] : '';
                let el2 = ai_currentabadiInfo_arry[i + 1] != undefined ? ai_currentabadiInfo_arry[i + 1] : '';
                let el3 = ai_currentabadiInfo_arry[i + 2] != undefined ? ai_currentabadiInfo_arry[i + 2] : '';

                infoSets.push([el1, el2, el3]);
            }

            // console.log(infoSets);
            return infoSets;
        });

        const abadisManageMode = ref('abadifiles');
        
        const callApproveFile = (e: any) => {
            let id = e.target.getAttribute('id');
            let uploader = e.target.getAttribute('uploader');
            // console.log(id, uploader);
            checkNApproveFile(id, uploader);
        }
        
        const callDownloadFile = (e: any) => {
            let fileFullName = e.target.getAttribute('filelocation') + '.' + e.target.getAttribute('fileextension');
            // console.log(fileFullName);
            checkNDownloadFile(fileFullName);
        }

        const callAbadiLimitDeletion = () => {
            let dr_changeRequestObj = {
                tochange: 'abadilimit',
                changetype: 'delete',
                uniqueid: ai_currentabadi.value.abadilimituuid
            };

            store.dispatch('dr_showReasonBox', true);
            store.dispatch('dr_changeRequestObj', dr_changeRequestObj);
        }

        const callFileDeletion = (e: any) => {
            let filelocation = e.target.getAttribute('filelocation');
            let fileextension = e.target.getAttribute('fileextension');

            let dr_changeRequestObj = {
                tochange: 'abadifile',
                changetype: 'delete',
                uniqueid: `${filelocation}...${fileextension}`,
                changesreqd: { abadilimituuid: ai_currentabadi.value.abadilimituuid } 
            };

            // console.log(filelocation, fileextension);
            store.dispatch('dr_showReasonBox', true);
            store.dispatch('dr_changeRequestObj', dr_changeRequestObj);
        }

        const abadisinfotable = ref();

        const callRequestDataChanges = () => {
            let els = Array.from(abadisinfotable.value.getElementsByClassName('changehelperclass'));

            const changeRequestedValues = els.map((el: any) => {
                el.disabled = true;

                let column = el.getAttribute('column');
                let oldvalue = el.getAttribute('oldvalue');
                let newvalue = el.value;
                if(oldvalue != el.value){
                    return { column, oldvalue, newvalue }
                }
            }).filter(el => el != undefined);

            console.log(changeRequestedValues);

            if(changeRequestedValues.length == 0){
                showGlobalToast('No Values To Update');
                return 0;
            }

            let changeRequestObj = {
                tochange: 'abadilimit',
                changetype: 'update',
                uniqueid: ai_currentabadi.value.abadilimituuid,
                changesreqd: JSON.stringify(changeRequestedValues)
            };

            store.dispatch('dr_changeRequestObj', changeRequestObj);
            store.dispatch('dr_showReasonBox', true);
        }

        const enableCell = (e: any) => {
            e.target.parentNode.firstElementChild.disabled = !e.target.parentNode.firstElementChild.disabled;
            e.target.parentNode.firstElementChild.focus();
        }

        const handleStyleColor = (e: any) => {
            let el = e.target;
            let oldvalue = el.getAttribute('oldvalue');

            if(oldvalue == el.value){
                el.style.color = 'black';
            } else {
                el.style.color = 'red';
            }
        }

        return {
            ai_currentabadi, ai_currentabadiInfo_mod, returnInputType,
            ai_abadilistfetchstatus, abadiObjAvailInStore,
            af_filesList, isApprover, abadisManageMode,
            renderDate, callApproveFile, callDownloadFile,
            callAbadiLimitDeletion, callFileDeletion,
            enableCell, abadisinfotable, callRequestDataChanges,
            handleStyleColor
        }
    },
})
